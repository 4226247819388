<template>
  <v-container fluid>
    <v-card class="border-top-blue">
      <v-card-text>
        <boards-list></boards-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import BoardsList from '../components/BoardsList';

export default {
  name: 'boards-page',
  components: {
    BoardsList
  }
};
</script>
