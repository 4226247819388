<template>
  <fragment>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="loading"
      :loading-text="$t('common.loadingData')"
      :no-data-text="$t('common.noData')"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" class="mb-4">
          <v-toolbar-title>{{ $t('boards.boards') }}</v-toolbar-title>
          <div class="flex-grow-1"></div>
          <v-btn small fab depressed color="primary" to="/boards/create">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <boards-list-filter
          :apply-filters="applyFilters"
          :applied-filters="filters"
        ></boards-list-filter>
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name[currentUser.lang] | truncate(50) }}
      </template>
      <template v-slot:item.price="{ item }">
        <span v-if="item.price">{{ item.price }}</span>
        <span v-else>{{ $t('common.free') }}</span>
      </template>
      <template v-slot:item.goldPrice="{ item }">
        <span v-if="item.goldPrice">{{ item.goldPrice }}</span>
        <span v-else>{{ $t('common.free') }}</span>
      </template>
      <template v-slot:item.status="{ item }">
        <v-tooltip top :color="getApprovalColor(item.status)">
          <template v-slot:activator="{ on }">
            <v-icon x-small :color="getApprovalColor(item.status)" v-on="on">
              mdi-circle
            </v-icon>
          </template>
          <span>{{ item.status }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.default="{ item }">
        <v-icon v-if="item.default" x-small color="app-green">
          mdi-circle
        </v-icon>
        <span v-else>-</span>
      </template>
      <template v-slot:item.new="{ item }">
        <span v-if="item.new">{{ $t('common.new') }}</span>
        <span v-else>-</span>
      </template>
      <template v-slot:item.createdBy="{ item }">
        {{ item.createdBy | truncate(20) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          x-small
          fab
          depressed
          color="primary"
          class="mr-1"
          :to="{ name: 'edit-board', params: { id: item.id } }"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          x-small
          fab
          depressed
          color="error"
          class="mr-1"
          @click="deleteBoard(item.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import BoardsListFilter from './BoardsListFilter';
import ConfirmModal from '../../shared/components/ConfirmModal';
import approvalStatusesMixin from '../../shared/mixins/approval.statuses.mixin';
import listItemsMixin from '../../shared/mixins/list.items.mixin';
import httpService from '../services/boards.http.service';

export default {
  name: 'boards-list',
  components: {
    BoardsListFilter
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser'
    })
  },
  mixins: [listItemsMixin, approvalStatusesMixin],
  data() {
    return {
      items: [],
      totalItems: 0,
      loading: true,
      headers: [
        {
          text: this.$i18n.t('common.name'),
          value: 'name',
          sortable: false
        },
        {
          text: this.$i18n.t('common.price'),
          value: 'price',
          sortable: false
        },
        {
          text: this.$i18n.t('common.promoPrice'),
          value: 'promoPrice',
          sortable: false
        },
        {
          text: this.$i18n.t('common.goldPrice'),
          value: 'goldPrice',
          sortable: false
        },
        {
          text: this.$i18n.t('common.status'),
          value: 'status',
          sortable: false
        },
        {
          text: this.$i18n.t('common.default'),
          value: 'default',
          sortable: false
        },
        {
          text: this.$i18n.t('common.new'),
          value: 'new',
          sortable: false
        },
        {
          text: this.$i18n.t('common.createdBy'),
          value: 'createdBy',
          sortable: false
        },
        {
          text: this.$i18n.t('common.actions'),
          value: 'action',
          sortable: false
        }
      ]
    };
  },
  methods: {
    async getItems(params) {
      this.loading = true;

      const { result } = await httpService.index(params);

      this.items = result.items;
      this.totalItems = result.totalItems;

      this.loading = false;
    },
    async deleteBoard(id) {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('boards.deleteConfirmText')
      });

      if (!confirmed) return;

      await httpService.del(id);

      this.resetList(false);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('boards.deleteSuccess')
      });
    }
  }
};
</script>
